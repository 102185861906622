import { axiosCooperativeServiceInstance } from '@core/axios/axios.cooperativeServiceConfig';
import { useQuery, UseQueryOptions } from 'react-query';

import { AddOnInterface } from './../interface/AddonInterface';

export const getAddOnList = async ({
  companyId
}: {
  companyId: string;
}): Promise<APIResponse<Array<AddOnInterface>>> => {
  const { data } = await axiosCooperativeServiceInstance.get(
    `/companyAddonSettings?companyId=${companyId}`
  );
  return data;
};

const useGetAddOnList = ({
  companyId,
  options
}: {
  companyId: string;
  options?: UseQueryOptions<APIResponse<Array<AddOnInterface>>>;
}) =>
  useQuery<APIResponse<Array<AddOnInterface>>>(
    'useGetAddOnList',
    () => getAddOnList({ companyId }),
    options
  );

export default useGetAddOnList;
