import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'activityReport',
      icon: 'activity',
      iconSize: 'micro',
      title: t('menu:activities'),
      children: [
        {
          id: 'activityReport.view',
          title: t('menu:activityReport'),
          navLink: '/activity/report'
        },
        {
          id: 'activityType.view',
          title: t('menu:activityType'),
          navLink: '/activity/type'
        }
      ]
    },
    {
      id: 'activityPlanner',
      icon: 'clipboardCheck',
      iconSize: 'micro',
      title: t('menu:planner'),
      children: [
        {
          id: 'activityPlanner.view',
          title: t('menu:activityPlanner'),
          navLink: '/planner/activity'
        }
      ]
    },
    {
      id: 'client',
      icon: 'client',
      iconSize: 'micro',
      title: t('menu:client'),
      children: [
        {
          id: 'client.view',
          title: t('menu:clientList'),
          navLink: '/client'
        }
      ]
    },
    {
      id: 'document',
      icon: 'document',
      iconSize: 'micro',
      title: t('menu:documents'),
      children: [
        {
          id: 'document.view',
          title: t('menu:documents'),
          navLink: '/documents'
        }
      ]
    },
    {
      id: 'users',
      icon: 'usergroup',
      iconSize: 'micro',
      title: t('menu:accounts'),
      children: [
        {
          id: 'users.view',
          title: t('menu:userList'),
          navLink: '/accounts/users'
        }
      ]
    }
  ];

  return { navigationConfig };
};
