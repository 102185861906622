import { localStorageService } from '@core/utils/localStorage';
import {
  EnglishActivityDetail,
  EnglishActivityPlanner,
  EnglishActivityReport,
  EnglishActivityType,
  EnglishClientDetail,
  EnglishClientList,
  EnglishClientModify,
  EnglishClientNew,
  EnglishCommon,
  EnglishDocumentDetail,
  EnglishDocumentList,
  EnglishDocumentModify,
  EnglishDocumentNew,
  EnglishLoginPage,
  EnglishMenu,
  EnglishProfile,
  EnglishUserDetail,
  EnglishUserList
} from '@i18n/locales/en';
import {
  BahasaActivityDetail,
  BahasaActivityPlanner,
  BahasaActivityReport,
  BahasaActivityType,
  BahasaClientDetail,
  BahasaClientList,
  BahasaClientModify,
  BahasaClientNew,
  BahasaCommon,
  BahasaDocumentDetail,
  BahasaDocumentList,
  BahasaDocumentModify,
  BahasaDocumentNew,
  BahasaLoginPage,
  BahasaMenu,
  BahasaProfile,
  BahasaUserDetail,
  BahasaUserList
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = 'en';
  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };
  const englishResources = {
    common: EnglishCommon,
    clientDetail: EnglishClientDetail,
    clientList: EnglishClientList,
    clientModify: EnglishClientModify,
    clientNew: EnglishClientNew,
    document: EnglishDocumentList,
    documentDetail: EnglishDocumentDetail,
    documentNew: EnglishDocumentNew,
    documentModify: EnglishDocumentModify,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    activityPlanner: EnglishActivityPlanner,
    activityReport: EnglishActivityReport,
    activityDetail: EnglishActivityDetail,
    activityType: EnglishActivityType,
    userList: EnglishUserList,
    userDetail: EnglishUserDetail,
    profile: EnglishProfile
  };
  const bahasaResources = {
    common: BahasaCommon,
    clientDetail: BahasaClientDetail,
    clientList: BahasaClientList,
    clientModify: BahasaClientModify,
    clientNew: BahasaClientNew,
    document: BahasaDocumentList,
    documentDetail: BahasaDocumentDetail,
    documentNew: BahasaDocumentNew,
    documentModify: BahasaDocumentModify,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    activityPlanner: BahasaActivityPlanner,
    activityReport: BahasaActivityReport,
    activityDetail: BahasaActivityDetail,
    activityType: BahasaActivityType,
    userList: BahasaUserList,
    userDetail: BahasaUserDetail,
    profile: BahasaProfile
  };
  const resources = {
    en: englishResources,
    id: bahasaResources
  };
  const config = {
    resources,
    lng,
    ns: [
      'login',
      'menu',
      'activityPlanner',
      'activityReport',
      'activityDetail',
      'activityType',
      'userList',
      'userDetail',
      'common',
      'clientDetail',
      'clientList',
      'clientNew',
      'clientModify',
      'document',
      'documentDetail',
      'documentNew',
      'documentModify'
    ]
  };
  const init = () => {
    i18n.use(initReactI18next).init(config);
  };
  return { i18n, init, changeLanguage };
};
