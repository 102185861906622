// FIXME : for whitelabeling some color at scheme below will come from .env depends on primary or secondary color

import { ColorDark, ColorGreen, ColorLight, ColorRed } from '@uangcermat/uikit-web';
import {
  CheckPickerColorSchemeInterface,
  ColorSchemeInterface,
  SelectPickerColorSchemeInterface,
  SidebarColorSchemeInterface,
  TableColorSchemeInterface
} from '@uangcermat/uikit-web/build/src/styles/products/productColorScheme.type';

const agenkuTableColorScheme: TableColorSchemeInterface = {
  pagination: {
    activePageNumberText: ColorDark.blackCoral
  }
};

const agenkuSidebarColorScheme: SidebarColorSchemeInterface = {
  background: ColorLight.whiteSolid,
  menuItem: {
    parent: {
      text: {
        default: ColorDark.bermudaGray,
        active: ColorLight.whiteSolid
      },
      icon: {
        default: ColorDark.bermudaGray,
        active: ColorLight.whiteSolid
      }
    },
    child: {
      text: {
        default: ColorDark.gumbo,
        active: ColorDark.blackCoral
      }
    }
  },
  collapsableMenu: {
    background: ColorLight.whiteSolid,
    active: process.env.BG_SIDE_BAR_ACTIVE_COLOR ?? ColorRed.americanRed,
    hover: {
      default: ColorDark.solitude,
      active: process.env.BG_SIDE_BAR_HOVER_COLOR ?? ColorRed.americanRed
    },
    icon: {
      default: ColorDark.bermudaGray,
      active: ColorLight.whiteSolid
    }
  },
  subMenuItem: {
    default: ColorLight.whiteSolid,
    active: ColorLight.whiteSmoke,
    hover: {
      background: ColorDark.solitude
    }
  },
  subMenuIndicator: {
    default: process.env.SUB_MENU_INDICATOR_COLOR ?? ColorRed.deepCarmine
  },
  footer: {
    borderRight: ColorDark.bermudaGray,
    text: {
      color: ColorDark.bermudaGray
    }
  }
};

const agenkuCheckPickerColorScheme: CheckPickerColorSchemeInterface = {
  selectedList: ColorGreen.pantoneGreen,
  count: ColorGreen.pantoneGreen,
  hoverColor: ColorLight.whiteSmoke,
  activeColor: ColorLight.whiteSmoke,
  checkBoxColor: ColorDark.gumbo
};

const agenkuSelectPickerColorScheme: SelectPickerColorSchemeInterface = {
  hoverColor: ColorLight.whiteSmoke,
  activeColor: ColorLight.whiteSmoke,
  addButtonColor: ColorLight.whiteSmoke
};

export const agenkuColorScheme: ColorSchemeInterface = {
  table: agenkuTableColorScheme,
  sidebar: agenkuSidebarColorScheme,
  checkPicker: agenkuCheckPickerColorScheme,
  selectPicker: agenkuSelectPickerColorScheme
};
